import React, { Component } from 'react'
import moment from 'moment'


import  '../../../node_modules/bootstrap/dist/css/bootstrap.css'
import '../../../node_modules/animate.css/animate.min.css'

import HolidayData from '../../Data/HolidayData'
import nz from '../../images/nz.svg'


export default class index extends Component {

     state={
         HolidayData 
     }


    componentDidMount(){

     const {HolidayData}=this.state
    
     const HolidayYear=HolidayData.filter((holidayItem)=>{
      return holidayItem.year=='2021'
    })

     this.setState({HolidayData:HolidayYear})
    }

   YearClick=(e)=>{
     //console.log(e.target.innerText)

     //导入数据
    const holiday=HolidayData
    
    //重新过滤数据
    const HolidayYear=holiday.filter((holidayItem)=>{
      return holidayItem.year==e.target.innerText
    })

    this.setState({HolidayData:HolidayYear})

   }

    render() {
      //console.log(this.state.HolidayData)
      const{HolidayData} =this.state
        return (
        <div>
           <div className='row '>

           <h3 className='text-primary m-2 animate__animated animate__backInRight'>New Zealand public holliday <img style={{width:'33px',height:'33px'}} src={nz} alt='nz flag'/></h3>

            <div className='col mt-1 d-flex justify-content-between'>
              <button onClick={this.YearClick} className='btn btn-outline-primary mx-2 '>2021</button>

              <button onClick={this.YearClick} className='btn btn-outline-info mx-3'>2022</button>

              <button onClick={this.YearClick} className='btn btn-outline-success'>2023</button>

              <button onClick={this.YearClick} className='btn btn-outline-warning mx-3'>2024</button>
            </div>
           </div>
           <div className='row'>
              <div className='col card mt-1 bg-light'>
                  <table className='table table-hover table-responsive' >
                  <thead>
                      <tr className='table-secondary'>
                          <th className='col'>Date</th>
                          <th className='col'>Day</th>
                          <th className='col'>Holiday</th>
                      </tr>
                  </thead>
                  <tbody>
                    {
                      HolidayData.map((holiday,index)=>{
                        return(
                          <tr key={holiday.id}>                       
                          <td>{moment(holiday.date).format('DD-MM-yyyy')}</td>
                          <td>{moment(holiday.date).format('ddd')}</td>
                          <td>{holiday.holiday}</td>                       
                        </tr>
                        )
                      })

                    }
                  </tbody>
                  </table>
              </div>
            </div>
        </div> 
 
        )
    }
}
