
import Holiday from './Components/Holiday'

import  '../node_modules/bootstrap/dist/css/bootstrap.css'
import './App.css';

function App() {
  return (
    <div className="container">
      <Holiday/>
    </div>
  );
}

export default App;
