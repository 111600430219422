
export default [
{id:'01',date:'2021-01-01',year:'2021', holiday:"New Year's Day"},
{id:'02',date:'2021-01-02',year:'2021', holiday:"Day after New Year's Day"},
{id:'03',date:'2021-01-04',year:'2021', holiday:'New Year Holiday'},
{id:'04',date:'2021-01-25',year:'2021', holiday:'Wellington Anniversary Day'},
{id:'05',date:'2021-02-01',year:'2021', holiday:'Auckland Anniversary Day'},
{id:'06',date:'2021-02-01',year:'2021', holiday:'Nelson Anniversary Day'},
{id:'07',date:'2021-02-06',year:'2021', holiday:'Waitangi Day'},
{id:'08',date:'2021-02-08',year:'2021', holiday:'Waitangi Day Holiday'},
{id:'09',date:'2021-03-08',year:'2021', holiday:'Taranaki Anniversary Day'},
{id:'10',date:'2021-03-22',year:'2021', holiday:'Otago Anniversary Day'},
{id:'11',date:'2021-04-02',year:'2021', holiday:'Good Friday'},
{id:'12',date:'2021-04-05',year:'2021', holiday:'Easter Monday'},
{id:'13',date:'2021-04-06',year:'2021', holiday:'Southland Anniversary Day'},
{id:'14',date:'2021-04-25',year:'2021', holiday:'Anzac Day'},
{id:'15',date:'2021-04-26',year:'2021', holiday:'Anzac Day Holiday'},
{id:'16',date:'2021-06-07',year:'2021', holiday:"Queen's Birthday"},
{id:'17',date:'2021-09-27',year:'2021', holiday:'South Canterbury Anniversary Day'},
{id:'18',date:'2021-10-22',year:'2021', holiday:"Hawkes' Bay Anniversary Day"},
{id:'19',date:'2021-10-25',year:'2021', holiday:'Labour Day'},
{id:'20',date:'2021-11-01',year:'2021', holiday:'Marlborough Anniversary Day'},
{id:'21',date:'2021-11-12',year:'2021', holiday:'Canterbury Anniversary Day'},
{id:'22',date:'2021-11-29',year:'2021', holiday:'Chatham Islands Anniversary Day'},
{id:'23',date:'2021-11-29',year:'2021', holiday:'Westland Anniversary Day'},
{id:'24',date:'2021-12-25',year:'2021', holiday:'Christmas Day'},
{id:'25',date:'2021-12-26',year:'2021', holiday:'Boxing Day'},
{id:'26',date:'2021-12-27',year:'2021', holiday:'Christmas Holiday'},
{id:'27',date:'2021-12-28',year:'2021', holiday:'Boxing Day Holiday'},


{id:'28',date:'2022-01-01',year:'2022', holiday:"New Year's Day"},
{id:'29',date:'2022-01-02',year:'2022', holiday:"Day after New Year's Day"},
{id:'30',date:'2022-01-03',year:'2022', holiday:'New Year Holiday'},
{id:'31',date:'2022-01-04',year:'2022', holiday:'New Year Holiday'},
{id:'32',date:'2022-01-24',year:'2022', holiday:'Wellington Anniversary Day'},
{id:'33',date:'2022-01-31',year:'2022', holiday:'Auckland Anniversary Day'},
{id:'34',date:'2022-01-31',year:'2022', holiday:'Nelson Anniversary Day'},
{id:'35',date:'2022-02-06',year:'2022', holiday:'Waitangi Day'},
{id:'36',date:'2022-02-07',year:'2022', holiday:'Waitangi Day Holiday'},
{id:'37',date:'2022-03-14',year:'2022', holiday:'Taranaki Anniversary Day'},
{id:'38',date:'2022-03-21',year:'2022', holiday:'Otago Anniversary Day'},
{id:'39',date:'2022-04-15',year:'2022', holiday:'Good Friday'},
{id:'40',date:'2022-04-18',year:'2022', holiday:'Easter Monday'},
{id:'41',date:'2022-04-19',year:'2022', holiday:'Southland Anniversary Day'},
{id:'42',date:'2022-04-25',year:'2022', holiday:'Anzac Day'},
{id:'43',date:'2022-06-06',year:'2022', holiday:"Queen's Birthday"},
{id:'44',date:'2022-06-24',year:'2022', holiday:'Matariki'},
{id:'45',date:'2022-09-26',year:'2022', holiday:'South Canterbury Anniversary Day'},
{id:'46',date:'2022-10-21',year:'2022', holiday:"Hawkes' Bay Anniversary Day"},
{id:'47',date:'2022-10-24',year:'2022', holiday:'Labour Day'},
{id:'48',date:'2022-10-31',year:'2022', holiday:'Marlborough Anniversary Day'},
{id:'49',date:'2022-11-11',year:'2022', holiday:'Canterbury Anniversary Day'},
{id:'50',date:'2022-11-28',year:'2022', holiday:'Chatham Islands Anniversary Day'},
{id:'51',date:'2022-11-28',year:'2022', holiday:'Westland Anniversary Day'},
{id:'52',date:'2022-12-25',year:'2022', holiday:'Christmas Day'},
{id:'53',date:'2022-12-26',year:'2022', holiday:'Boxing Day'},
{id:'54',date:'2022-12-27',year:'2022', holiday:'Christmas Holiday'},


{id:'55',date:'2023-01-01',year:'2023', holiday:"New Year's Day"},
{id:'56',date:'2023-01-02',year:'2023', holiday:"Day after New Year's Day"},
{id:'57',date:'2023-01-03',year:'2023', holiday:'New Year Holiday'},
{id:'58',date:'2023-01-23',year:'2023', holiday:'Wellington Anniversary Day'},
{id:'59',date:'2023-01-30',year:'2023', holiday:'Auckland Anniversary Day'},
{id:'60',date:'2023-01-30',year:'2023', holiday:'Nelson Anniversary Day'},
{id:'61',date:'2023-02-06',year:'2023', holiday:'Waitangi Day'},
{id:'62',date:'2023-03-13',year:'2023', holiday:'Taranaki Anniversary Day'},
{id:'63',date:'2023-03-20',year:'2023', holiday:'Otago Anniversary Day'},
{id:'64',date:'2023-04-07',year:'2023', holiday:'Good Friday'},
{id:'65',date:'2023-04-10',year:'2023', holiday:'Easter Monday'},
{id:'66',date:'2023-04-11',year:'2023', holiday:'Southland Anniversary Day'},
{id:'67',date:'2023-04-25',year:'2023', holiday:'Anzac Day'},
{id:'68',date:'2023-06-05',year:'2023', holiday:"Queen's Birthday"},
{id:'69',date:'2023-07-14',year:'2023', holiday:'Matariki'},
{id:'70',date:'2023-09-25',year:'2023', holiday:'South Canterbury Anniversary Day'},
{id:'71',date:'2023-10-20',year:'2023', holiday:"Hawkes' Bay Anniversary Day"},
{id:'72',date:'2023-10-23',year:'2023', holiday:'Labour Day'},
{id:'73',date:'2023-10-30',year:'2023', holiday:'Marlborough Anniversary Day'},
{id:'74',date:'2023-11-17',year:'2023', holiday:'Canterbury Anniversary Day'},
{id:'75',date:'2023-11-27',year:'2023', holiday:'Chatham Islands Anniversary Day'},
{id:'76',date:'2023-12-04',year:'2023', holiday:'Westland Anniversary Day'},
{id:'77',date:'2023-12-25',year:'2023', holiday:'Christmas Day'},
{id:'78',date:'2023-12-26',year:'2023', holiday:'Boxing Day'},


{id:'79',date:'2024-01-01',year:'2024', holiday:"New Year's Day"},
{id:'80',date:'2024-01-02',year:'2024', holiday:"Day after New Year's Day"},
{id:'81',date:'2024-01-22',year:'2024', holiday:'Wellington Anniversary Day'},
{id:'82',date:'2024-01-29',year:'2024', holiday:'Auckland Anniversary Day'},
{id:'83',date:'2024-01-29',year:'2024', holiday:'Nelson Anniversary Day'},
{id:'84',date:'2024-02-06',year:'2024', holiday:'Waitangi Day'},
{id:'85',date:'2024-03-11',year:'2024', holiday:'Taranaki Anniversary Day'},
{id:'86',date:'2024-03-25',year:'2024', holiday:'Otago Anniversary Day'},
{id:'87',date:'2024-03-29',year:'2024', holiday:'Good Friday'},
{id:'88',date:'2024-04-01',year:'2024', holiday:'Easter Monday'},
{id:'89',date:'2024-04-02',year:'2024', holiday:'Southland Anniversary Day'},
{id:'90',date:'2024-04-25',year:'2024', holiday:'Anzac Day'},
{id:'91',date:'2024-06-03',year:'2024', holiday:"Queen's Birthday"},
{id:'92',date:'2024-06-28',year:'2024', holiday:'Matariki'},
{id:'93',date:'2024-09-23',year:'2024', holiday:'South Canterbury Anniversary Day'},
{id:'94',date:'2024-10-25',year:'2024', holiday:"Hawkes' Bay Anniversary Day"},
{id:'95',date:'2024-10-28',year:'2024', holiday:'Labour Day'},
{id:'96',date:'2024-11-04',year:'2024', holiday:'Marlborough Anniversary Day'},
{id:'97',date:'2024-11-15',year:'2024', holiday:'Canterbury Anniversary Day'},
{id:'98',date:'2024-12-02',year:'2024', holiday:'Chatham Islands Anniversary Day'},
{id:'99',date:'2024-12-02',year:'2024', holiday:'Westland Anniversary Day'},
{id:'100',date:'2024-12-25',year:'2024', holiday:'Christmas Day'},
{id:'101',date:'2024-12-26',year:'2024', holiday:'Boxing Day'}


]



